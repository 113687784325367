<template>
  <div class="m-b-10">
    <div v-for="(config, idx) in validGameInfoConfigs" v-bind:key="idx" class="m-b-10">
      <config-dropdown
        v-if="config.type === 'dropdown'"
        :game="game"
        :is-loading="isLoading"
        :default-config="defaultConfig"
        :dropdown-data="config"
        @configChange="onConfigChange"
      />
    </div>
  </div>
</template>

<script>
import ConfigDropdown from "@/components/games/new_game/ConfigDropdown.vue";

export default {
  name: "NewGameSettings",
  components: {ConfigDropdown},
  data() {
    return {
      gameConfig: {}
    }
  },
  props: {
    game: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: () => {},
    },
    defaultConfig: {
      type: Object,
      default: () => {}
    },
    assignmentMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validGameInfoConfigs() {
      if (!this.gameInfo || !this.gameInfo.config) {
        return []
      }
      if (this.assignmentMode) {
        return this.gameInfo.config;
      }
      console.log(this.gameInfo.config);
      return this.gameInfo.config.filter(x => !x.assignmentModeOnly);
    }
  },
  methods: {
    onConfigChange(value) {
      this.gameConfig[value.id] = value.value.id;

      // Calculate total price based on settings
      const price = this.calculatePriceBasedOnConfigSelections();

      this.$emit('newPriceChange', price);
      this.$emit('configChange', this.gameConfig);
    },
    calculatePriceBasedOnConfigSelections() {
      let currentPrice = 0;
      let regularPrice = 0;
      Object.keys(this.gameConfig).forEach((configId) => {
        const configObj = this.gameInfo.config.find(x => x.id === configId);
        if(configObj) {
          const priceObj = configObj.values.find(x => x.id === this.gameConfig[configId]);
          if (priceObj){
            currentPrice += priceObj.prices.current;
            regularPrice += priceObj.prices.regular;
          }
        }
      });
      return {
        current: currentPrice,
        regular: regularPrice
      };
    }
  },
}
</script>