<template>
  <div>
    <div class="row form-group m-b-10">
      <label class="col-md-3 col-form-label" for="memory-size">{{ $t("games.dispatch.size") }}</label>
      <div class="col-md-9">
        <select
            v-model="difficulty"
            class="form-control"
            id="memory-size"
            :disabled="isLoading"
        >
          <option
              v-for="(opt, idx) in gameInfo.difficulties"
              :key="idx"
              :value="opt.id"
          >
            {{ getDifficultyStr(opt.id) }}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-group m-b-10">
      <label class="col-md-3 col-form-label" for="memory-difficulty"
      >{{ $t("games.dispatch.content") }}</label
      >
      <div class="col-md-9">
        <select
            v-model="value_type"
            class="form-control"
            id="memory-difficulty"
            :disabled="isLoading"
        >
          <option v-for="(opt, idx) in value_types" :key="idx" :value="opt">
            {{ getContentStr(opt) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import {getGameSettingName} from "@/_helpers/games_helper";

export default {
  name: "NewMemorySettings",
  data() {
    return {
      difficulty: "",
      value_types: [],
      value_type: "",
    };
  },
  props: {
    game: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    getDifficultyStr(id) {
      return getGameSettingName(this.game, {id: id}, this);
    },
    getContentStr(id) {
      return this.$t("games.games.memory.difficulties." + id);
    },
  },
  watch: {
    difficulty: function (newDifficulty) {
      // Triggered when user changes difficulty
      this.difficulty = newDifficulty;
      const difficultyObj = this.gameInfo.difficulties.filter(
          (x) => x.id === newDifficulty
      )[0];
      this.value_types = difficultyObj.config.value_type_options;
      this.value_type = difficultyObj.config.value_type_options[0];
      this.$emit("priceChange", difficultyObj);
    },
    value_type: function (new_letter_type) {
      // Triggered when user changes difficulty
      this.value_type = new_letter_type;
      this.$emit("configChange", {value_type: this.value_type});
    },
    gameInfo: function (newInfo) {
      // Triggered on initial load of the page
      if ("difficulties" in newInfo && newInfo.difficulties.length > 0) {
        this.difficulty = newInfo.difficulties[0].id;
        this.value_types = newInfo.difficulties[0].config.value_type_options;
        this.value_type = newInfo.difficulties[0].config.value_type_options[0];
        this.$emit("priceChange", newInfo.difficulties[0]);
      }
    },
  },
};
</script>

<style scoped></style>
