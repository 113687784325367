<template>
  <div class="row form-group m-b-10">
    <label class="col-md-3 col-form-label" for="sudoku-difficulty"
    >{{ $t("games.dispatch.size") }}</label
    >
    <div class="col-md-9">
      <select
          v-model="difficulty"
          class="form-control"
          id="sudoku-difficulty"
          :disabled="isLoading"
      >
        <option
            v-for="(opt, idx) in gameInfo.difficulties"
            :key="idx"
            :value="opt.id"
        >
          {{ getDifficultyStr(opt.id) }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import {getGameSettingName} from "@/_helpers/games_helper";

export default {
  name: "NewSimpleMazeSettings",
  data() {
    return {
      difficulty: "",
    };
  },
  props: {
    game: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    getDifficultyStr(id) {
      return getGameSettingName(this.game, {id: id}, this);
    },
  },
  watch: {
    difficulty: function (newDifficulty) {
      // Triggered when user changes difficulty
      this.difficulty = newDifficulty;
      const priceObj = this.gameInfo.difficulties.filter(
          (x) => x.id === newDifficulty
      )[0];
      this.$emit("priceChange", priceObj);
    },
    gameInfo: function (newInfo) {
      // Triggered on initial load of the page
      if ("difficulties" in newInfo && newInfo.difficulties.length > 0) {
        this.difficulty = newInfo.difficulties[0].id;
        this.$emit("priceChange", newInfo.difficulties[0]);
      }
    },
  },
};
</script>

<style scoped></style>
