<template>
  <div>
    <div class="row form-group m-b-10">
      <label class="col-md-4 col-form-label" for="cc-difficulty"
      >{{ $t("games.dispatch.size") }}</label
      >
      <div class="col-md-8">
        <select
            v-model="difficulty"
            class="form-control"
            id="cc-difficulty"
            :disabled="isLoading"
        >
          <option
              v-for="(opt, idx) in gameInfo.difficulties"
              :key="idx"
              :value="opt.id"
          >
            {{ getDifficultyStr(opt.id) }}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-group m-b-10">
      <label class="col-md-4 col-form-label" for="cc-alphabet"
      >{{ $t("games.dispatch.encryptionAlphabet") }}</label
      >
      <div class="col-md-8">
        <select
            v-model="allLetters"
            class="form-control"
            id="cc-alphabet"
            :disabled="isLoading"
        >
          <option
              v-for="(opt, idx) in alphabetOptions"
              :key="idx"
              :value="opt.value"
          >
            {{ opt.title }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import {getGameSettingName} from "@/_helpers/games_helper";

export default {
  name: "NewCaesarCypherSettings",
  data() {
    return {
      difficulty: "",
      allLetters: false,
    };
  },
  props: {
    game: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    alphabetOptions() {
      return [
        {
          title: this.$t("games.games.caesarCypher.difficulties.decryptedAlphabet"),
          value: false,
        },
        {
          title: this.$t("games.games.caesarCypher.difficulties.entireAlphabet"),
          value: true,
        },
      ];
    },
  },
  methods: {
    getDifficultyStr(id) {
      return getGameSettingName(this.game, {id: id}, this);
    },
  },
  watch: {
    difficulty: function (newDifficulty) {
      // Triggered when user changes difficulty
      this.difficulty = newDifficulty;
      const priceObj = this.gameInfo.difficulties.filter(
          (x) => x.id === newDifficulty
      )[0];
      this.$emit("priceChange", priceObj);
    },
    gameInfo: function (newInfo) {
      // Triggered on initial load of the page
      if ("difficulties" in newInfo && newInfo.difficulties.length > 0) {
        this.difficulty = newInfo.difficulties[0].id;
        this.$emit("priceChange", newInfo.difficulties[0]);
      }
    },
    allLetters: function (newValue) {
      this.$emit("configChange", {all_letters: newValue});
    },
  },
  created() {
    this.$emit("configChange", {all_letters: this.allLetters});
  },
};
</script>

<style scoped></style>
