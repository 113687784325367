<template>
  <div>
    <div class="row form-group m-b-10">
      <label class="col-md-3 col-form-label" for="mc-content">{{ $t("games.dispatch.content") }}</label>
      <div class="col-md-9">
        <select
            v-model="difficulty"
            class="form-control"
            id="mc-content"
            :disabled="isLoading"
        >
          <option
              v-for="(opt, idx) in gameInfo.difficulties"
              :key="idx"
              :value="opt.id"
          >
            {{ getDifficultyStr(opt.config) }}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-group m-b-10">
      <label class="col-md-3 col-form-label">{{ $t("games.dispatch.operations") }}</label>
      <div class="col-md-9 rm-form-checkboxes">
        <div class="form-check form-check-inline">
          <input
              v-model="operators"
              class="form-check-input"
              type="checkbox"
              id="opPlus"
              value="+"
              disabled
              checked
          />
          <label class="form-check-label" for="opPlus">{{ $t("games.dispatch.addition") }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
              v-model="operators"
              class="form-check-input"
              type="checkbox"
              id="opMinus"
              :disabled="isLoading"
              value="-"
          />
          <label class="form-check-label" for="opMinus">{{ $t("games.dispatch.subtraction") }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
              v-model="operators"
              class="form-check-input"
              type="checkbox"
              id="opMultiply"
              :disabled="isLoading"
              value="x"
          />
          <label class="form-check-label" for="opMultiply">{{ $t("games.dispatch.multiplication") }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
              v-model="operators"
              class="form-check-input"
              type="checkbox"
              id="opDivide"
              :disabled="isLoading"
              value="/"
          />
          <label class="form-check-label" for="opDivide">{{ $t("games.dispatch.division") }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getGameSettingName} from "@/_helpers/games_helper";

export default {
  name: "NewMathCrosswordSettings",
  data() {
    return {
      difficulty: "",
      operators: ["+"],
    };
  },
  props: {
    game: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    getDifficultyStr(config) {
      return getGameSettingName("mathCrossword", {config: config}, this);
    },
  },
  watch: {
    difficulty: function (newDifficulty) {
      // Triggered when user changes difficulty
      this.difficulty = newDifficulty;
      const priceObj = this.gameInfo.difficulties.filter(
          (x) => x.id === newDifficulty
      )[0];
      this.$emit("priceChange", priceObj);
    },
    gameInfo: function (newInfo) {
      // Triggered on initial load of the page
      if ("difficulties" in newInfo && newInfo.difficulties.length > 0) {
        this.difficulty = newInfo.difficulties[0].id;
        this.$emit("priceChange", newInfo.difficulties[0]);
      }
    },
    operators: function (newValue) {
      this.$emit("configChange", {operators: newValue});
    },
  },
  created() {
    this.$emit("configChange", {operators: this.operators});
  },
};
</script>

<style scoped></style>
