<template>
  <div class="row">
    <label class="col-md-3 col-form-label" for="mc-content">{{ $t(dropdownData.label) }}</label>
    <div class="col-md-9">
      <select
          v-model="dropdownOption"
          class="form-control"
          id="mc-content"
          :disabled="isLoading"
      >
        <option
            v-for="(opt, idx) in dropdownData.values"
            :key="idx"
            :id="opt.id"
            :value="opt.value"
        >
          <span v-if="dropdownData?.valuesAsStrings">{{ opt.value }}</span>
          <span v-else>{{ getValueString(opt, dropdownData.valueString) }}</span>
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfigDropdown",
  data() {
    return {
      dropdownOption: "",
    };
  },
  props: {
    game: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dropdownData: {
      type: Object,
      default: () => {}
    },
    defaultConfig: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getValueString(opt, valueString) {
      if(opt.valueI18N) {
        return this.$t("games.games."  + this.game + "." + opt.valueI18N);
      }
      let baseStr = this.$t("games.games."  + this.game + "." + valueString);
      return baseStr.replace("{0}", opt.value);
    },
    constructPriceObj(priceObj) {
      return {
        "id": this.dropdownData.id,
        "value": priceObj
      }
    }
  },
  watch: {
    dropdownOption: function (newOption) {
      this.dropdownOption = newOption;
      const priceObj = this.dropdownData.values.filter(
          (x) => x.id === newOption
      )[0];
      this.$emit("configChange", this.constructPriceObj(priceObj));
    },
    dropdownData: function (newDropdownData) {
      if ("values" in newDropdownData && newDropdownData.values.length > 0) {
        const selectedValue = newDropdownData.values[0];
        this.dropdownOption = selectedValue.id;
        this.$emit("configChange", this.constructPriceObj(selectedValue));
      }
    },
  },
  created() {
    if (this.dropdownData && this.dropdownData.values.length > 0){
      let selectedValue = this.dropdownData.values[0];
      // default selected value (if exists in dropdown !!!)
      if(this.defaultConfig && this.defaultConfig[this.dropdownData.id]) {
        const defaultSelectedId = this.defaultConfig[this.dropdownData.id];

        const selectCandidate = this.dropdownData.values.find(x => x.id === defaultSelectedId);
        if (selectCandidate) {
          selectedValue = selectCandidate;
        }
      }

      this.dropdownOption = selectedValue.id;
      this.$emit("configChange", this.constructPriceObj(selectedValue));
    }
  }
}
</script>